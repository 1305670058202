body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #eeece8 /*#f0f7fb*/;
}

a.soft {
    text-decoration: none;
    color: inherit;
}
a.soft:hover,
a.soft:active {
    text-decoration: none;
    border-bottom: 1px dashed #b3762c;
}

a.light {
    text-decoration: none;
    color: #e25a00;
}
a.light:hover,
a.light:active {
    text-decoration: none;
    border-bottom: 1px dashed #b3622c;
}

.page {
    color: var(--primary-text-color);
    padding-top: 24px;
    padding-bottom: 40px;
    padding-left: 1px;
    padding-right: 1px;
}

.page h1 {
    margin: 0;
    text-align: center;
}

.page .section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 5px;
}
.page .footer,
.page .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.logo2 {
    margin: 30px;
}

.smallerText {
    font-size: 12px !important;
}
.smallText {
    font-size: 10px !important;
}
.verySmallText {
    font-size: 9px !important;
}
.veryVerySmallText {
    font-size: 8px !important;
}
.bold {
    font-weight: bold;
}

.disabled {
    color: #999999;
}
.optional {
    color: #ab9f8d;
}
.separateData {
    color: #935b2b;
}

.inRow {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    justify-content: center;
}
.inRow > * {
    margin-right: 5px;
    margin-left: 5px;
}
.inCol {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.rightAlign {
    text-align: right;
    justify-content: flex-end;
}
.leftAlign {
    text-align: left;
    justify-content: flex-start;
}
.centerAlign {
    text-align: center;
    justify-content: center;
}

.noselect,
label {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.narrow {
    width: 260px;
}

.tip {
    font-size: 11px !important;
    font-style: italic;
}
.tip p {
    margin: 0;
}
.caption {
    font-size: 12px;
    font-style: italic;
    margin-bottom: 5px;
}

.field {
    margin: 18px 0px;
}
.shortField {
    width: 100px;
}
.field label {
    font-size: 15px;
}

.readOnlyFrame {
    background: #eae5e2;
    padding: 8px;
    border-radius: 5px;
    border: #cbab99 1px solid;
}

.nowrap {
    white-space: nowrap;
}

.tokenFrame {
    width: auto;
    display: inline;
    margin: 13px 5px;
    padding: 9px 12px 5px 6px;
    font-family: "Courier New", Arial, sans-serif;
    font-size: 12px;
    font-weight: bold;
    color: #2b2e32;
}
.tokenFrame button {
    position: relative;
    top: -5px;
}

.readOnlyCode {
    background: var(--bg-color-input);
    padding: 6.8px 14px;
    border-radius: 6px;
    border: var(--border-color-input) 1px solid;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

input.readOnlyCode,
input:disabled:not(input[type='checkbox']) {
    background: var(--bg-color-input-disabled) !important;
}

.smallList {
    background: #eae5df;
    padding: 8px;
    border-radius: 5px;
    border: #cbaf99 1px solid;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
}

.smallItem {
    padding: 5px 8px;
    border-radius: 4px;
    border: #decfc1 1px solid;
    background: #f5f1ec;
    font-size: 12px;
    margin: 3px 3px;
}

.tinyItem {
    padding: 3px;
    border-radius: 4px;
    border: var(--border-color-tiny-item) 1px solid;
    background: var(--bg-color-tiny-item);
    font-size: 9px;
    margin: 2px 2px;
    display: flex;
    align-items: center;
    gap: 3px;
    color: var(--primary-text-color);
}
button.tinyItem:hover {
    background: var(--bg-color-tiny-item-hover);
}

.react-tooltip {
    font-size: 15px !important;
    padding: 4px 12px 4px 12px !important;
    font-weight: normal;
    transition: opacity 0.3s ease-out;
    background-color: var(--bg-color-tooltip) !important;
    color: var(--text-color-tooltip) !important;
    margin-top: 2px !important;
    opacity: 1;
    z-index: 50;
}
.react-tooltip-arrow {
    z-index: -1;
}

.pagination-btn {
    background: var(--bg-color-pagination);
    border-radius: 3px;
    width: 40px;
    height: 40px;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color-pagination);
    font-size: 15px;
    border: 0;
    cursor: pointer;
}

.pagination-btn.left:hover,
.pagination-btn.right:hover {
    background: var(--bg-color-hover-pagination);
}

.pagination-btn svg {
    width: 24px;
}

.pagination-btn svg path {
    stroke: var(--text-color-pagination);
}

.pagination-btn.left {
    transform: rotate(-90deg);
}

.pagination-btn.right {
    transform: rotate(90deg);
}

i.arrow {
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;
    top: 4px;
}
.arrow.right {
    background: url(../../public/icons/arrow-right2.png) center center no-repeat;
}
.arrow.down {
    background: url(../../public/icons/arrow-right2.png) center center no-repeat;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
.arrow.left {
    background: url(../../public/icons/arrow-right2.png) center center no-repeat;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
.arrow.up {
    background: url(../../public/icons/arrow-right2.png) center center no-repeat;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}
.emoji {
    background: url(../img/emoji.png) center center no-repeat;
}

i.plus {
    display: inline-block;
    width: 17px;
    height: 17px;
    position: relative;
    top: 3px;
    background: url(../../public/icons/plus.png) center center no-repeat;
}

.icon.warning {
    display: inline-block;
    width: 32px;
    height: 32px;
    transform: scale(0.65);
    position: relative;
    background: url(../../public/icons/warning.png) center center no-repeat;
}

.floating {
    position: relative;
}
button.floating {
    padding: 0px;
}

button.arrow:hover {
    filter: brightness(1.05);
}
button.arrow:active {
    filter: brightness(0.95);
}
button.arrow:hover {
    filter: brightness(1.05);
}
button.arrow:active {
    filter: brightness(0.95);
}
button.arrow:hover {
    filter: brightness(1.05);
}
button.arrow:active {
    filter: brightness(0.95);
}
button.arrow:hover {
    filter: brightness(1.05);
}
button.arrow:active {
    filter: brightness(0.95);
}

button.text-and-icon *:not(.icon):first-child {
    margin-right: -8px;
}
button.text-and-icon .icon:last-child {
    margin-left: 8px;
}
button.text-and-icon *:not(.icon):last-child {
    margin-left: -8px;
}
button.text-and-icon .icon:first-child {
    margin-right: 8px;
}

button.text-and-icon .icon {
    display: inline-block;
    width: 20px;
    height: 0;
    position: relative;
    top: -5px;
}

.page input[type="text"],
.page input[type="password"] {
    border: 1px solid #7a553d;
    border-radius: 4px;
    padding: 6px;
    font-size: 16px;
    cursor: text;
}
.page input[type="text"]::placeholder {
    color: #a1a8aa;
    font-style: italic;
    font-size: 14px;
}
.page input[type="text"].smallText {
    font-size: 14px;
}
textarea {
    cursor: text;
}

.page input[type="checkbox"] {
    margin-right: 8px;
    cursor: pointer;
}
.checkbox {
    cursor: pointer;
}
.page li.checkbox,
div.checkbox {
    display: flex;
    flex-direction: row !important;
}
.page div.checkbox label.smallerText {
    margin-top: 1px;
}

.page select {
    background: var(--bg-color-select);
    border-color: var(--border-color-select);
    color: var(--primary-text-color);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    padding: 6px;
    font-size: 11px;
    transition: border-color 0.15s ease-in-out;
}

.page select:not(.page select:disabled):hover {
    border-color: var(--border-color-focus-input);
}

.page select.big-select {
    font-size: 16px;
    height: 32px;
}

.page select.smallerText {
    padding: 4px;
}
/*.select.smallText {
    height: 28px;
}*/

.widget {
    all: initial;
}

/* .page button {
    border: none;
    cursor: pointer;
    display: inline-block;
    outline: 0;
} */
button.common,
button.secondary,
button.dev-test {
    background-color: #a78b7b;
    font-size: 16px;
    color: #ffffff;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
button.common:not(.small),
button.secondary:not(.small),
button.dev-test:not(.small) {
    padding: 14px 28px;
}
.page a {
    text-decoration: none;
}
button.common:hover {
    background-color: #ba8f76;
}
button.common:disabled {
    background-color: #a09895;
    color: #bebebe;
}

button.secondary {
    background-color: #e0d5cd;
    border: 1px solid #cbaa89;
    color: #824c2f;
}
button.secondary:hover {
    background-color: #d5bbae;
}
button.secondary .icon {
    opacity: 0.7;
}
button.secondary:hover .icon {
    opacity: 0.9;
}

button.green {
    background-color: var(--bg-color-success-fill-btn); /*  #55af55 */
    padding: 0px 14px 0 9px;
    font-size: 16px;
    color: #ffffff;
    border-radius: 3px;
    height: 46px;
}
button.green:hover {
    background-color: var(--bg-color-success-fill-btn-hover);
}
button.green .icon {
    opacity: 0.9;
}

button.small2 {
    padding: 6px 10px 6px 2px !important;
    font-size: 12px !important;
}

.page form.simple {
    max-width: 480px;
    background: none;
    padding: 15px 15px 20px;
    margin: 5px 0px;
    border-radius: 0px;
    border: none;
}

.page form.thin {
    max-width: 480px;
    padding: 15px 15px 25px;
    margin: 20px auto;
    border-radius: 6px;
    border: 1px solid #7a503d;
}

.page form {
    background: var(--bg-color-input);
    border: 1px solid var(--border-color-input);

    max-width: 800px;
    padding: 22px 18px;
    margin: 20px auto;
    border-radius: 6px;
}
.page form > ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.page form > ul > li {
    margin-bottom: 10px;
    min-height: 35px;
}
.page form > ul > li .field-style {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 8px;
    outline: none;
    border: 1px solid #e0bfb0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.page form > ul > li .field-style:focus {
    box-shadow: 0 0 5px #b0cfe0;
    border: 1px solid #b0cfe0;
}
.page form > ul > li .field-split {
    width: 49%;
}
.page form > ul > li .field-full {
    width: 100%;
}
.page form > ul > li input.align-left {
    float: left;
}
.page form > ul > li input.align-right {
    float: right;
}
.page form > ul > li textarea {
    height: 40px;
    border: 1px solid #7a553d;
    border-radius: 4px;
    padding: 6px;
    font-size: 14px;
}
.page form > ul > li input[type="button"],
.page form > ul > li input[type="submit"] {
    -moz-box-shadow: inset 0px 1px 0px 0px #b17139;
    -webkit-box-shadow: inset 0px 1px 0px 0px #b16339;
    box-shadow: inset 0px 1px 0px 0px #b16939;
    background-color: #884a21;
    border: 1px solid #5e3317;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    padding: 8px 18px;
    text-decoration: none;
    font: 12px Arial, Helvetica, sans-serif;
}
.page form > ul > li input[type="button"]:hover,
.page form > ul > li input[type="submit"]:hover {
    background: linear-gradient(to bottom, #a2562d 5%, #a86233 100%);
    background-color: #9e5728;
}

.page form > ul > li {
    display: flex;
    justify-content: center;
    align-items: center;
    /*flex-direction: row;*/
}
.page form.left-align > ul > li  {
    justify-content: start;
    align-items: start;
}
.page form > ul > li:not(.checkbox) > div {
    display: flex;
    flex-direction: column;
}
.page form > ul > li > div > div:not([class]) {
    margin: 0 10px;
}

.page form > ul > li.field > div:first-child {
    text-align: right;
    padding: 0 10px 0 20px;
    width: 80px;
}

.page form > ul > li.field > div:last-child {
    width: 220px;
    text-align: left;
    padding: 0 30px 0 10px;
}

.page form.simple > ul > li.field > div:first-child {
    width: 120px;
}

.page form > ul > li.submit {
    margin-bottom: 5px;
}
.page form > ul > li.submit > div {
    width: auto;
}

.medium {
    width: 40%;
}
textarea.medium {
    height: 100px;
}

.holder.wide {
    width: 100%;
}
textarea.wide {
    width: 100% !important;
    max-width: 100% !important;
    height: 80px;
    box-sizing: border-box;
    border: 1px solid var(--border-color-input);
    background: var(--bg-color-input);
    border-radius: 6px;
    color: var(--primary-text-color);
    padding: 6.8px 14px;
}
.page form.wide {
    max-width: none;
    width: 600px;
}
.page form.wide > ul > li.field,
form.wide > * > ul > li.field {
    display: flex;
    flex-direction: column;
}
.page form.wide > ul > li.field > div,
form.wide > * > ul > li.field > div {
    margin: 2px 0px;
    text-align: left;
    width: 600px;
    padding: 0;
}
.page form .required {
    display: inline-block;
    color: #e40300;
    padding: 0 3px;
}
.page form .required::after {
    content: "*";
}

.displayErrors input:invalid {
    border-color: #f51e08;
}

.holder {
    width: 0;
    height: 0;
    overflow: visible;
    margin: 0 !important;
}

.error {
    position: relative;
    color: #f51e08;
    font-size: 10px;
    top: 0;
    width: 100%;
    text-align: center;
}
.field .error {
    left: 8px;
}

.tinyTip {
    position: relative;
    color: #e46327;
    font-size: 10px;
    top: 5px;
    width: 100%;
    text-align: center;
}

/* main menu */

.mainmenu {
    overflow: hidden;
    background-color: #26191b /*#2e353f*/;
    font-family: Helvetica, sans-serif;
}

.mainmenu > a,
.mainmenu > div > a {
    float: left;
    color: #f2f2f2;
    font-size: 17px;
    text-decoration: none;
    text-align: center;
}

.mainmenu > a.right,
.mainmenu > div > a.right {
    float: right;
}

.mainmenu-logo > div {
    background-image: url(../../public/logo_new.png);
    background-repeat: no-repeat;
    background-position: center center;
    display: block;
    width: 256px;
    height: 50px;
    padding: 2px 15px;
    margin-right: 40px;
}

.mainmenu-list {
    padding: 0 30px 0 10px;
}

.mainmenu-list > a {
    padding: 17px 16px;
    min-width: 70px;
}

.mainmenu-list > a:hover {
    background-color: #705441;
    color: white;
}
.mainmenu-list > a.active {
    background-color: #926b51;
    color: white;
}

/* mainmenu */

/* menu */

.menu {
    overflow: hidden;
    font-family: Helvetica, sans-serif;
    margin: 20px 20px;
}

.menu a {
    color: #f2f2f2;
    font-size: 17px;
    text-decoration: none;
    text-align: left;
    display: block;
}

.menu-list {
    width: 270px;
    padding: 0;
    background-color: #26191b;
}

.menu-list a {
    padding: 5px 15px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.menu-list a:hover {
    background-color: #705441;
    color: white;
}
.menu-list a.active {
    background-color: #926b51;
    color: white;
}

.menu-list a .icon {
    margin: 10px 10px 10px 0;
    top: 0;
    flex: 0 0 20px;
}

/* ~menu */

/* horizontal menu */

.horz-menu {
    overflow: hidden;
    font-family: Helvetica, sans-serif;
}

.horz-menu-list {
    padding: 0;
    background-color: #eeece8;
    border: 1px solid #b9b4b5;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: min-content;
    margin: 0px auto;
    border-radius: 6px;
}

.horz-menu-list a {
    padding: 4px 10px 12px;
    border: 1px solid rgba(255, 255, 255, 0);
    width: 120px;
    display: block;
    text-align: center;
    text-decoration: none;
    color: #969696;
    font-size: 17px;
    border-radius: 6px;
    filter: brightness(0.7);
    cursor: auto;
}

.horz-menu-list a:hover {
    background-color: #d5c3b3;
    border: 1px solid rgba(38, 25, 27, 0.3);
    color: white;
    filter: brightness(1);
    cursor: pointer;
}
.horz-menu-list a.active {
    background-color: #bb9d87;
    border: 1px solid #26191b;
    color: white;
    filter: brightness(1);
    cursor: auto;
}

.horz-menu-list a .icon {
    margin: 2px 10px 2px 0;
    top: 5px;
    flex: 0 0 20px;
    opacity: 1;
}
.horz-menu-list a:hover .icon,
.horz-menu-list a.active .icon {
    opacity: 1;
}

/* !horizontal menu */

.topstats {
    float: right;
    display: flex;
    flex-direction: column;
    min-width: 120px;
    color: white;
    padding: 2px 20px;
    margin: 0 20px;
    background-color: #6dbb35;
    height: 50px;
    cursor: default;
    font-size: 14px;
}
.topstats.negative {
}
.topstats > div {
    padding: 5px 0 0px;
}
.topstats .balance {
    font-size: 12px;
    font-style: italic;
}
.topstats .balance .sum {
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    /*text-shadow: rgba(69, 178, 73, 0.7) 0 0 2px, rgba(78, 165, 100, 0.7) 0 0 1px;*/
    color: rgb(242, 255, 232);
    text-shadow: rgba(5, 21, 8, 0.5) 0 0 2px, rgba(9, 33, 11, 0.5) 0 0 1px;
}
.balance .sum.negative {
    color: rgb(255, 205, 196);
    text-shadow: rgba(199, 43, 24, 0.7) 0 0 2px, rgba(169, 42, 32, 0.8) 0 0 1px;
}
.blue {
    color: rgb(65, 164, 255);
}
.green {
    color: rgb(52, 177, 66);
}
.weakgreen {
    color: rgb(147, 110, 0);
}
.gray {
    color: #a1a1a1 !important;
}
.red {
    color: #ff3e3c !important;
}
.yellow {
    color: #c18f00 !important;
}
.orange {
    color: var(--secondary-text-color) !important;
}
.text-default {
    color: #3e342d !important;
}
.strikethrough {
    text-decoration: line-through;
}

.text-blue {
    color: #8d7b47;
}
.text-gray {
    color: #cbc2bb;
}

.topstats .username {
    font-weight: bold;
}

.warnTopPanel {
    background-color: #ff9f43;
    font-size: 13px;
    padding: 3px 10px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #302600;
    box-sizing: border-box;
}
.warnTopPanel.strong {
    background-color: #ea5455;
    color: #400b07;
    font-weight: bold;
}
.warnTopPanelHolder {
    width: 100%;
    overflow: visible;
    position: fixed;
    z-index: 50;
}

.right-menu-page {
    display: flex;
    margin: 0 8%;
}

.right-menu-page > div:first-child {
    flex: 20%;
    min-width: 270px;
}
.right-menu-page > div:last-child {
    flex: 70%;
}

button.copy-to-clipboard {
    background: url(../../public/icons/copy2.png) center center no-repeat;
    width: 20px;
    height: 20px;
    padding: 0;
    border: none;
    outline: 0;
    position: relative;
    top: 1px;
    margin: 0 4px 0 2px;
}
button.copy-to-clipboard:hover {
    filter: brightness(1.05);
}
button.copy-to-clipboard:active {
    filter: brightness(0.95);
}

.top-up-button {
    background: url(../../public/icons/top-up.png) center center no-repeat;
    width: 16px;
    height: 16px;
    padding: 0;
    border: none;
    outline: 0;
    position: relative;
    top: 1.5px;
    margin: 0 6px 0 6px;
    filter: brightness(0.95);
}
.top-up-button:hover {
    filter: brightness(1.05);
}

button.edit {
    background: url(../../public/icons/edit2.png) center center no-repeat;
    width: 20px;
    height: 20px;
    padding: 0;
    border: none;
    outline: 0;
    position: relative;
    margin: 0 0 0 5px;
    top: -1px;
}
button.edit:hover {
    filter: brightness(1.05);
}
button.edit:active {
    filter: brightness(0.95);
}

.pushIcon {
    background: url(../img/push.png) center center no-repeat;
}
.pushPressedIcon {
    background: url(../img/push_p.png) center center no-repeat;
}
.pushPressedIcon:after,
.pushIcon:after {
    content: "";
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
}

.activeIcon {
    background: url(../img/active.png) center center no-repeat;
}
.inactiveIcon {
    background: url(../img/inactive.png) center center no-repeat;
}

.checkIcon {
    background: url(../img/check.png) center center no-repeat;
    position: relative;
    top: 5px;
}

.pages {
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.pages > * {
    min-width: 36px;
    margin: 4px 5px;
}
.pages button {
    min-height: 38px;
}
.pages .prev button {
    /*padding: 4px 8px 8px 0;*/
    padding: 0 8px 6px 8px;
}
.pages .next button {
    /*padding: 4px 0 8px 8px;*/
    padding: 0 8px 6px 8px;
}
.pages .current {
    min-width: 40px;
    padding: 8px 0;
    border-radius: 3px;
    background-color: #e7ddd9;
}

.page .form {
    width: 500px;
    margin: 0 auto;
    text-align: left;
}
.page .wide-form {
    width: 100%;
    min-width: 800px;
    margin: 0 auto;
    text-align: left;
}

.page .center-section {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
}
.page .left-align-section {
    width: 400px;
    margin: 0 0;
    text-align: left;
}

.page h2 {
    font-size: 15px;
    margin: 16px 0 24px;
}
/* .page > h2 {
    margin-top: 30px;
} */
.page h3 {
    font-size: 14px;
    /* font-style: italic; */
    font-weight: normal;
    margin: 0 0 10px;
}

.page ul.points {
    list-style-type: circle;
}
.page ul {
    list-style: none;
}
.page > ul > li,
.page > * > ul > li {
    margin: 4px 10px;
    padding: 2px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.page > ul > li.smaller,
.page > * > ul > li.smaller {
    margin: 3px 10px 2px;
}
.page > ul > li > div,
.page > * > ul > li > div {
    margin: 0 5px;
}
.page > ul.info,
.page > * > ul.info {
    width: 80%;
}

.page > div.info,
.page > * > div.info {
    margin: 0 auto;
    width: 80%;
    text-align: left;
}
div.info > p {
    line-height: 1.5;
}

/* .page button {
    cursor: pointer;
    outline: 0;
} */

.page button.icon,
div.icon,
span.icon {
    display: inline-block;
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    padding: 0;
    border: none;
}
button.common.icon {
    min-width: 40px;
}
span.icon {
    vertical-align: bottom;
}
button.small2 .icon {
    min-width: 18px !important;
}

/*    top: 4px;
    margin: 0 6px 0 2px;

 */

.page button.dev-test {
    background-color: #62af7f;
}
.page button.dev-test:hover {
    background-color: #559671;
}
.page button.dev-test:disabled {
    background-color: #739682;
    color: #a7beb2;
}

.small-spinner {
    width: 12px;
    height: 8px;
    display: inline-block;
    vertical-align: baseline;
    overflow: visible;
}
.small-spinner > div {
    position: relative;
    background: url(../img/small-spinner.svg) center center no-repeat;
    width: 12px;
    height: 10px;
    top: -1px;
    left: 1px;
}

.spinner {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: baseline;
    overflow: visible;
}
.spinner > div {
    position: relative;
    background: url(../img/spinner.svg) center center no-repeat;
    width: 20px;
    height: 20px;
}
/* icons */
.upIcon {
    background: url(../img/up_icon.png) center center no-repeat;
    width: 10px;
    height: 9px;
    display: inline-block;
    vertical-align: baseline;
    margin: 0px 1px 0px 0px;
}
.up2Icon {
    background: url(../img/up2_icon.png) center center no-repeat;
    width: 10px;
    height: 9px;
    display: inline-block;
    vertical-align: baseline;
    margin: 0px 1px 0px 0px;
}
.downIcon {
    background: url(../img/down_icon.png) center center no-repeat;
    width: 10px;
    height: 9px;
    display: inline-block;
    vertical-align: baseline;
    margin: 0px 1px 0px 0px;
}
.down2Icon {
    background: url(../img/down2_icon.png) center center no-repeat;
    width: 10px;
    height: 9px;
    display: inline-block;
    vertical-align: baseline;
    margin: 0px 1px 0px 0px;
}
.stableIcon {
    background: url(../img/stable_icon.png) center center no-repeat;
    width: 7px;
    height: 9px;
    display: inline-block;
    vertical-align: baseline;
    margin: 0px 1px 0px 0px;
}
.emptyIcon {
    width: 5px;
    height: 9px;
    display: inline-block;
    vertical-align: baseline;
}

.btnLoadIcon {
    background: url(../img/btn_load_ico.gif) center center no-repeat;
    width: 16px;
    height: 11px;
    display: inline-block;
    vertical-align: baseline;
    margin: 4px 32px 3px;
}

.loading {
    background: url(../img/loading.gif) center center no-repeat;
    width: 50px;
    height: 16px;
    display: inline-block;
    vertical-align: baseline;
    margin: 4px 16px 3px;
}
.long-loading {
    background: url(../img/long-loading.gif) center center no-repeat;
    width: 220px;
    height: 19px;
    display: inline-block;
    vertical-align: baseline;
    margin: 4px 32px 3px;
}

.repeatSmallIcon {
    background: url(../img/repeat_small.png) center center no-repeat;
    width: 8px;
    height: 8px;
}
.crossSmallIcon {
    background: url(../img/cross_small.png) center center no-repeat;
    width: 8px;
    height: 8px;
}
.crossSmall2Icon {
    background: url(../img/cross_small2.png) center center no-repeat;
    width: 10px;
    height: 10px;
}

button.audio {
    display: inline-block;
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 0;
    border: none;
    outline: 0;
    width: 26px;
    height: 26px;
    vertical-align: baseline;
    margin: 4px;
}
button.audio:hover {
    filter: brightness(1.1);
}
button.audio:active {
    filter: brightness(0.95);
}
button.audio.pause {
    background: url(../img/audio_pause.png) center center no-repeat;
}
button.audio.play {
    background: url(../img/audio_play.png) center center no-repeat;
}

li.react-datepicker__time-list-item {
    height: 16px !important;
}
button.react-datepicker__navigation--next {
    padding-right: 14px !important;
}
.react-datepicker-time__header {
    content: "fsdf";
}

.emoji-mart {
    font-size: 14px !important;
}
.emoji-mart-title-label {
    font-size: 16px !important;
}
section.emoji-mart {
    border: 1px solid #716153;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 1px solid rgba(118, 104, 87, 0.3);
    box-shadow: 1px 1px 4px rgba(106, 90, 76, 0.6);
}
.emoji-mart-scroll {
    height: 215px !important;
}

@font-face {
    font-family: "NotoColorEmoji";
    src: url("../../public/fonts/NotoColorEmoji.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto";
    src: url("../../public/fonts/Roboto-Regular.ttf") format("truetype");
}

.emoji-text {
    font-family: Tahoma, NotoColorEmoji, serif;
}

.separator {
    flex-basis: 100%;
    height: 0;
}

.smallTable {
    background: var(--bg-color-payment-pending);
    border-radius: 5px;
    border: var(--border-color-payment-pending) 1px solid;

    display: flex;
    flex-direction: column;
    font-size: 11px;
}

.smallTable > div {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: stretch;
}
.smallTable > div:not(:last-child) {
    border-bottom: var(--border-color-payment-pending) 1px solid;
}
.smallTable > div > div {
    margin: 0;
    padding: 6px 6px;
}
.smallTable > div > div.header {
    font-style: italic;
}
.smallTable > div > div:not(.header) {
    background: var(--bg-color-input);
}
.smallTable > div > div:not(:last-child) {
    border-right: var(--border-color-payment-pending) 1px solid;
}
.smallTable .itemFill {
    flex-grow: 1;
}

.smallTable .nowrap {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.smallTable > div:first-child > div:first-child {
    border-top-left-radius: 5px;
}
.smallTable > div:last-child > div:first-child {
    border-bottom-left-radius: 5px;
}
.smallTable > div:first-child > div:last-child {
    border-top-right-radius: 5px;
}
.smallTable > div:last-child > div:last-child {
    border-bottom-right-radius: 5px;
}

.clickable {
    cursor: pointer;
}

.inRow .headerContainer {
    margin-left: 20px;
    margin-right: 20px;
}
.inRow .headerContainer:first-child {
    margin-left: 0;
}
.inRow .headerContainer:last-child {
    margin-right: 0;
}
.headerContainer *:first-child {
    margin-bottom: 2px;
}

.icon.tiny {
    background-repeat: no-repeat;
    background-position: center center;
    width: 12px;
    height: 12px;
    margin: 0 1px;
}
.icon.tiny.apptype1 {
    background-image: url(../../public/icons/android_s2.png);
}
.icon.tiny.apptype2 {
    background-image: url(../../public/icons/ios_s2.png);
}
.icon.tiny.apptype3 {
    background-image: url(../../public/icons/pwa_s2.png);
}

.modal-anchor {
    position: relative;
    height: 0;
}
.modal-wnd-background {
    display: none;
    position: fixed;

    z-index: 1000; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.25); /* Black w/ opacity */

    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    padding: 15px;
}

/* Modal Content/Box */
.modal-wnd-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: var(--bg-color-card);
    box-shadow: var(--box-shadow-card);
    background-clip: padding-box;
    border-radius: 6px;
    outline: 0;
    max-width: 700px;
    margin: auto;
    padding: 24px;
}

.image-modal .modal-wnd-content {
    max-width: 500px;
}

/* The Close Button */

.modal-wnd-content .close {
    box-sizing: content-box;
    background-color: var(--bg-color-card);
    width: 18px;
    height: 18px;
    padding: 6px;
    color: #4b465c;
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 4.5L5 13.5' stroke='%23a5a3ae' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14 4.5L5 13.5' stroke='white' stroke-opacity='0.2' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5 4.5L14 13.5' stroke='%23a5a3ae' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5 4.5L14 13.5' stroke='white' stroke-opacity='0.2' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: center;
    /* background-size: contain; */
    background-repeat: no-repeat;
    border: 0;
    border-radius: 6px;
    opacity: 1;
    cursor: pointer;
    box-shadow: var(--box-shadow-card);
    transition: all 0.23s ease 0.1s;
    position: absolute;
    top: -12px;
    right: -12px;
}

html:not([dir="rtl"]) .modal-wnd-content .close:hover {
    transform: scale(1.15);
}

.modal-wnd-content form {
    margin: 0;
    max-width: 100%;
    background: none;
    border: none;
    padding: 0;
}

.modal-wnd-content form > ul > li {
    flex-direction: column;
    align-items: start;
}

.modal-wnd-content form > ul > li.field > div:first-child {
    width: 100%;
    text-align: left;
    margin: 0 0 5px;
    padding: 0;
    font-weight: 600;
}

.modal-wnd-content form > ul > li.field > div:last-child {
    width: 100%;
    padding: 0;
}

.modal-wnd-content form .field {
    margin-top: 0;
}

.modal-wnd-content form.wide {
    width: auto;
}
.modal-wnd-content form.wide > ul > li.field > div,
form.wide > * > ul > li.field > div {
    width: 100%;
}

.credit-limit {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 13px;
}

.select .css-13cymwt-control,
.select .css-t3ipsp-control,
.select .css-16xfy0z-control {
    background: var(--bg-color-select);
    border-color: var(--border-color-select);
    cursor: pointer;
}

.select * {
    color: var(--primary-text-color) !important;
    font-size: 13px;
}

.select.small * {
    font-size: 11px;
}

.select .css-1u9des2-indicatorSeparator,
.select .css-894a34-indicatorSeparator {
    background: var(--border-color-select);
}

.select .css-1xc3v61-indicatorContainer svg path {
    fill: var(--border-color-select);
}

.select .css-1nmdiq5-menu {
    background: var(--bg-color-select) !important;
}

.select.traffic-distribution-select [role='option'] {
    background: none;
}

.select.traffic-distribution-select .css-1ee4t35-option {
    background-color: #2684FF !important;
}

.select.traffic-distribution-select [role='listbox'] div:hover, 
.select .css-d7l1ni-option {
    background-color: rgba(0, 0, 0, 0.2) !important;
    cursor: pointer;
}

.select.css-3iigni-container {
    opacity: 0.7;
    cursor: default;
}

.select input {
    border: 0px !important;
}

.select .css-1jqq78o-placeholder {
    opacity: 0.7;
}

.select .css-1p3m7a8-multiValue {
    background: var(--bg-color-card);
}

.select .css-1nmdiq5-menu {
    margin-top: 2px !important;
}

.select .css-13cymwt-control:hover {
    border-color: var(--border-color-focus-input);
}

/* CARD */

.card {
    background-clip: padding-box;
    box-shadow: var(--box-shadow-card);
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: var(--bg-color-card);
    background-clip: border-box;
    border-radius: 6px;
    padding: 24px;
    border: 1px solid var(--border-color-card);
}

.card-header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 24px;
}

.card-body {
    flex: 1 1 auto;
}

.page.flows-form form.wide {
    width: 100%;
    background: none;
    padding: 0;
    margin: 0;
    border: 0;
}

.page.flows-form form.wide > ul > li.field > div {
    width: 100%;
}

.page.flows-form form.wide > ul > li.field {
    margin: 0 0 10px;
}

.flex-container-centering {
    display: flex;
    align-items: center;
    justify-content: center;
}
