.tabs-wrapper {
    overflow-x: auto;
    padding-bottom: 16px;
    margin-top: 16px;
}

.tabs {
    display: flex;
    border-bottom: 1px solid var(--border-color-tabs);
}

.tab {
    padding: 10px 16px;
    cursor: pointer;
    position: relative;

    transition: color 0.3s;
    font-size: 14px;
}

.tab.active {
    color: #1890ff;
    text-shadow: 0 0 0.25px currentcolor;
}

.tab::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: transparent;
    transition: background-color 0.3s;
}

.tab.active::after {
    background-color: #1890ff; /* Цвет подчеркивания */
}

.tab:hover {
    color: #1890ff;
}

.tab-content {
    display: flex;
}
