.pushlist {
    margin: 0 auto 20px;
}

.pushlist > ul {
   display: flex;
   flex-direction: column;
   gap: 10px;
   padding: 0;
}

.pushlist > ul > li {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    list-style-image: none;
    border-radius: 6px;
    background-color: var(--bg-color-route);
    border: 1px solid var(--border-color-route);
    padding: 16px;
    box-shadow: var(--box-shadow-route);
    margin: 0;
    word-wrap: break-word;
}
.pushlist > ul > li > div {
    padding: 6px 0;
}

.pushlist > ul > li.header {
    background-color: #eee3db;
}
.pushlist .header .stats {
    border-left: 1px solid #e2ccbc;
}

.pushlist .message {
    width: 70%;
    text-align: left;
    border-left: 1px solid var(--border-color-navbar);
    padding-left: 10px;
    padding-top: 10px;
}
.pushlist .message .title {
    font-size: 14px;
    font-weight: 600;
}
.pushlist .message .body {
    font-size: 12px;
}

.pushlist .stats {
    text-align: center;
    border-left: 1px solid var(--border-color-navbar);
    padding: 12px 5px 12px 12px;
    white-space: nowrap;
    width: 85px;
}
.pushlist .actions {
    border-left: 1px solid var(--border-color-navbar);
    width: 50px;
    display: flex;
    justify-content: end;
}
.pushlist .actions .push-icon-wrapper:hover {
border-color: var(--fill-icons-hover);
}
.pushlist .actions .push-icon-wrapper:hover svg path {
    fill: var(--fill-icons-hover);
}
.pushlist .stats {
    cursor: pointer;
}
.pushlist .stats a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #3e342d;
}
.pushlist .stats .zero {
    color: var(--secondary-text-color);
    font-size: 12px;
}
button.button-stats {
    background: url(../../../public/icons/stats.png) center center no-repeat;
    width: 25px;
    height: 20px;
    padding: 0;
    border: none;
    outline: 0;
    position: relative;
    margin: 0 0 0 5px;
    top: -1px;
}
.pushRepeatIcon {
    background: url(../../img/push_repeat.png) center center no-repeat;
    width: 29px !important;
}

.pushlist .section {
    align-items: flex-end;
}

.pushlist-desc a {
    color: var(--secondary-text-color);
    cursor: pointer;
}

.pushlist-desc .push-icon-wrapper {
    display: inline-block;
    margin-bottom: -5px;
    margin-top: 5px;
    position: relative;
    transform: rotate(45deg);
}

.pushlist-desc .push-icon-wrapper svg {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
}

/*nth-child(odd)*/

.pushlist .info {
    padding: 0;
    width: 13%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.pushlist .info .subinfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 2px;
}

.pushlist .info .name {
    font-size: 16px;
    font-weight: 600;
}
.pushlist .info .date {
    font-size: 10px;
    color: var(--secondary-text-color);;
}
.pushlist .info .id {
    font-size: 10px;
    color: #d4b7a3;
    overflow: visible;
    width: 0;
}
.pushlist .info .id-only {
    color: var(--secondary-text-color);;
    font-size: 14px;
}

.pushlist > ul.info,
.pushlist > * > ul.info {
    margin: 4px 12px;
}
.pushlist > ul.info > li,
.pushlist > * > ul.info > li {
    padding: 0;
    margin: 0;
}

.pushlist .info .rightIcons {
    margin: 3px 0 0;
    width: 0px;
}
.pushlist .info .rightIcons > * {
    float: right;
}

.newPush > ul > li.field,
.newPush > * > ul > li.field {
    margin: 2px 10px;
}

.newPush .card {
    margin-top: 16px;
}

.newPush form {
    background: none;
    border: none;
    padding: 0;
    width: 100% !important;
    margin: 0;
}

.newPush form.wide > ul > li.field > div,
form.wide > * > ul > li.field > div {
    width: 100% !important;
}

.newPush form li:first-child {
    margin-top: 0 !important;
}

.newPush .react-datepicker-wrapper {
    max-width: 180px;
    width: 100%;
}

.newPush .react-datepicker-wrapper input {
    padding-left: 6px !important;
    text-align: center;
}

.newPush .emoji-btn {
    background-color: var(--bg-color-emoji-btn);
    padding: 0;
    border: 0;
    border-radius: 4px;
    height: 22px;
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.newPush .emoji-btn:hover {
    background-color: var(--bg-color-hover-emoji-btn);
    opacity: 1;
}
